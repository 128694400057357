import { makeSegmentIdentifyCall, makeSegmentInteractionCall } from '../../Shared/analytics';
import { logError } from '../../Shared/tracking-logger';
const ApplyFilterLabel = 'Home Page Apply Filter';
export function setupHomePageAnalytics() {
    makeSegmentIdentifyCall();
}
export function trackMarketSelected(selectedMarket, marketId) {
    makeSegmentInteractionCall({
        label: ApplyFilterLabel,
        value_market: selectedMarket,
        value_market_uuid: marketId,
    });
}
export function trackWorkspaceTypeSelected(selectedTypeId, containedProductsIds) {
    if (!selectedTypeId) {
        logError('[trackWorkspaceTypeSelected]-Invalid selected workspace type ID for tracking.');
        return;
    }
    makeSegmentInteractionCall({
        label: ApplyFilterLabel,
        value_workspace: selectedTypeId,
        value_workspace_include: containedProductsIds,
    });
}
export function trackIdeaImagesCTA() {
    makeSegmentInteractionCall({
        label: 'Click - Ideas Card - Image',
    });
}
export function trackIdeaSubcategoriesCTA() {
    makeSegmentInteractionCall({
        label: 'Click - Ideas Card - Subcategory',
    });
}
export function trackIdeaHeadingsCTA() {
    makeSegmentInteractionCall({
        label: 'Click - Ideas Card - Heading',
    });
}
export function trackIdeaDescriptionsCTA() {
    makeSegmentInteractionCall({
        label: 'Click - Ideas Card - Description',
    });
}
export function trackIdeaReadMoreCTA() {
    makeSegmentInteractionCall({
        label: 'Click - Cards/Articles Banner - Read on - Link',
    });
}
export function trackStartSubmitCTA() {
    makeSegmentInteractionCall({
        label: 'Homepage Start Search'
    });
}
